import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: "/assets/images/icons/carroattrezzi.png",
        title: 'Soccorso stradale',
        description: 'In caso di automezzo in panne o sinistrato, la nostra carrozzeria mette a disposizione un carroattrezzi con cui provvederà, in piena sicurezza, al traino del veicolo ed al trasporto dei passeggeri.'
    },
    {
        icon: "/assets/images/icons/clima.png",
        title: 'Clima',
        description: "Tra i diversi servizi di manutenzione, ci occupiamo anche della ricarica del gas dell'aria condizionata e, qualora necessario, della sostituzione del filtro e della riparazione dell'impianto di climatizzazione."
    },
    {
        icon: "/assets/images/icons/gestione-sinistri.png",
        title: 'Gestione sinistri',
        description: "Il nostro staff ti fornirà un'assistenza completa in caso di incidenti stradali, occupandosi, oltre che del recupero e della riparazione del mezzo incidentato, di tutto l'iter burocratico relativo all’assicurazione."
    },
    {
        icon: "/assets/images/icons/stoccaggio-gomme.png",
        title: 'Stoccaggio gomme',
        description: "Affidati all'esperienza dei nostri professionisti per la sostituzione stagionale degli pneumatici ed il loro corretto stoccaggio, evitando così di incorrere in danneggiamenti e deformazioni che ne pregiudichino la sicurezza."
    },
    {
        icon: "/assets/images/icons/lavaggio.png",
        title: 'Lavaggio',
        description: "Offriamo servizi di lavaggio della carrozzeria esterna dei veicoli e di igienizzazione degli interni. Il nostro staff si occuperà in maniera meticolosa della pulizia della tappezzeria e della smacchiatura dei sedili, preservandone le superfici."
    },
    {
        icon: "/assets/images/icons/meccanico.png",
        title: 'Riparazioni meccaniche',
        description: "Presso la nostra carrozzeria potrai trovare anche un ampio servizio di riparazioni meccaniche e manutenzione ordinaria e straordinaria. Affidati alla nostra competenza per rendere il tuo veicolo un mezzo sicuro ed affidabile."
    }
]


class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2">
                                <div className="icon">
                                    <img src={val.icon} />
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
