import React from 'react';
import { FaGoogle, FaInstagram, FaFacebookF, FaEnvelope } from "react-icons/fa";

const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/cekristorante/' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/cek_ristorante/' },
    { Social: <FaEnvelope />, link: 'mailto:cekristorante@gmail.com' },
    { Social: <FaGoogle />, link: 'https://www.google.com/maps/place/Cek/@44.3922552,7.5485257,15z/data=!4m5!3m4!1s0x0:0x84efdd5eefdaaa1f!8m2!3d44.3922552!4d7.5485257' },
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30">
            <div className="wrapper plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="text">
                                <p>Cek di Daziano Vladimir</p>
                                <p>P.Iva IT03877230049<br />
                                    Via Amedeo Rossi, 25 - 12100 Cuneo (CN)<br />
                                    <a href="mailto:cekristorante@gmail.com">cekristorante@gmail.com</a><br />
                                    <a href="tel:+393471759618">+39 347 1759618</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-self-start opening-hours">
                        <div className="inner">
                            <div className="text">
                                <p>Orari di apertura:</p>
                                <p>
                                    <span>Lun - Ven cena:</span> 19,30 - 22,30<br />
                                    <span>Sab - Dom pranzo:</span> 12,30 - 14,30<br />
                                    <span>Sab - Dom cena:</span> 19,30 - 22,30
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="logo text-lg-right text-center text-sm-left mb_sm--20">
                                <a href="#home">
                                    <img src="/assets/images/logo/logo.png" alt="Logo images" />
                                </a>
                            </div>
                            <div className="social-share-inner">
                                <ul className="social-share social-style--2 d-flex justify-content-end liststyle mt--15">
                                    {SocialShare.map((val, i) => (
                                        <li key={i}><a href={`${val.link}`} target="_blank">{val.Social}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;