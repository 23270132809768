import React, { Component, Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import Slider from "react-slick";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Ristorante Cek',
        subtitle: '',
        description: 'La tradizione piemontese rivisitata e valorizzata',
        buttonText: 'Prenota',
        buttonLink: '/#prenota'
    }
]


class CreativeLanding extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader() { }
    render() {

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 3,
            swipeToSlide: true,
            pauseOnHover: false,
            focusOnSelect: false,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 575,
                    settings: {
                        fade: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Fragment>
                <Helmet pageTitle="Ristorante Cek" />
                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images" />
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images" />
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'ristorante', 'filosofia', 'menù', 'contattaci']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#ristorante">Ristorante</a></li>
                                    <li><a href="#filosofia">Filosofia</a></li>
                                    <li><a href="/assets/menù-cek-ristorante.pdf" target="_blank">Menù</a></li>
                                </Scrollspy>
                            </nav>
                            <div className="header-btn">
                                <a className="rn-btn" href="#prenota">
                                    <span>Prenota</span>
                                </a>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image--2" data-black-overlay="5">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                <br />
                                                {value.subtitle ? <h2 className="title theme-gradient">{value.subtitle}</h2> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Intro Area */}
                <div className="about-area ptb--120 bg_color--5" id="ristorante">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7 mb--20">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Cek</h2>
                                            <p className="description">
                                                Il nostro ristorante, nel centro storico di Cuneo, ti aspetta per farti provare una sorprendente esperienza di gusto. Potrai assaporare <b>pietanze tipiche della tradizione piemontese</b>, rivisitate in maniera creativa, e caratterizzate da una <b>particolare attenzione alla qualità della materia prima</b>. Non potrà mancare, naturalmente, un calice di pregiato vino, ad esaltarne magistralmente i sapori.<br /><br />
                                                Un team giovane ma qualificato ed attento, fortemente orientato all’arte dell’ospitalità, alla cura dei dettagli, alla ricerca continua di rinnovamento.<br />
                                                Un locale intimo, un luogo ideale in cui trascorrere romantiche cene di coppia o sereni pranzi domenicali.<br />
                                                Puoi inoltre prenotare il tuo tavolo all'interno del nostro <b>spazio esterno</b>, un riservato e curato dehor, pronto per accoglierti nelle giornate più calde.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/contact/cek-ristorante.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Intro Area */}

                {/* Start Service Area  */}
                {/*                 <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Service Area  */}

                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <Slider {...settings}>
                            <div>
                                <img src="/assets/images/blocco-centrale/blocco-centrale-1.jpg" />
                            </div>
                            <div>
                                <img src="/assets/images/blocco-centrale/polenta-con-nocciole.JPG" />
                            </div>
                            <div>
                                <img src="/assets/images/blocco-centrale/secondo-piatto-con-funghi-porcini.JPG" />
                            </div>
                            <div>
                                <img src="/assets/images/blocco-centrale/panna-cotta.jpg" />
                            </div>
                            <div>
                                <img src="/assets/images/blocco-centrale/dolce-con-pistacchi.JPG" />
                            </div>
                        </Slider>
                    </div>
                </div>

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5" id="filosofia">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/cucina-piemontese-rivisitata.JPG" alt="Chi siamo" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">

                                        <div className="section-title">
                                            <h2 className="title">La nostra filosofia</h2>
                                            <p className="description">
                                                Ciò che contraddistingue in particolar modo la nostra filosofia è la <b>grande passione per i prodotti del territorio piemontese</b>. Tra i nostri piatti potrai trovare soprattutto ingredienti come funghi, selvaggina, erbe spontanee e verdure dell’orto. La nostra proposta di vini è il risultato di un'attenta selezione tra i prodotti di cantine di piccoli produttori locali.<br /><br />
                                                La materia prima è quella della tradizione, ma le tecniche di preparazione e di presentazione delle pietanze sono rivolte all'<b>innovazione</b> ed all’estetica moderna.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="prenota">
                    <Contact />
                </div>
                {/* End Contact Us */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default CreativeLanding;