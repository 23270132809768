import React, { Component } from "react";

class ContactTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnPhone: '',
            rnMessage: ''
        };
    }

    sendMail(e) {
        if (this.state.rnEmail && this.state.rnName) {
            e.preventDefault();
            fetch("https://bagubits.it/mail.php?" +
                "email=" + (this.state.rnEmail ? this.state.rnEmail : '') +
                "&name=" + (this.state.rnName ? this.state.rnName : '') +
                "&phone=" + (this.state.rnPhone ? this.state.rnPhone : '') +
                "&message=" + (this.state.rnMessage ? this.state.rnMessage : '') +
                "&to=cekristorante@gmail.com" +
                "&from=info@cekristorante.it" +
                "&from_name=Ristorante Cek"
            ).then(res => {
                console.log(res);
                alert("Grazie! Il tuo messaggio è stato inviato correttamente.");
                this.state = {
                    rnName: '',
                    rnEmail: '',
                    rnPhone: '',
                    rnMessage: ''
                };
            }).catch(err => {
                console.log(err);
                alert("Grazie! Il tuo messaggio è stato inviato correttamente.");
            });
        }
    }

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Prenota un tavolo!</h2>
                                <p className="description">Per maggiori informazioni o per richiedere la disponibilità</p>
                            </div>
                            <div>
                                <a type="button" href="https://cekristorante.superbexperience.com/" value="Prenota" target="_blank" className="rn-button-style--2 btn-solid">Prenota</a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/contact/contact-1.jpg" alt="trydo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;